import { GetServerSidePropsContext, NextPageContext } from 'next';
import Router from 'next/router';
import { setCookie } from 'nookies';

import { RequestError, RequestStatusError } from '@agentero/service-errors';

import { queryClient } from 'packages/services/QueryCache';
import { signInRoute } from 'routes';

import { getIsomorphicPathname } from '../getIsomorphicPathname';
import { LogLevel, addLog } from '../logger';

export const isomorphicRedirect = (
	ctx: NextPageContext | GetServerSidePropsContext,
	url: string,
	replace = false
) => {
	const { res } = ctx ?? {};

	if (res) {
		queryClient.clear(); //TODO: This is done to avoid agent caching in sever if we redirect the clean of _document.tsx is not executed
		res.writeHead(302, { Location: url });
		res.end();
	} else if (typeof window !== 'undefined') {
		if (replace) {
			Router.replace(url);
			return;
		}
		//TODO: Add NextJS navigation when all pages are in NextJS side,
		//we make hard navigation because we don't know if navigation is in back-ag or NextJS side
		window.location.href = url;
	}
};

export const handleNotAuthorizeRequestError = (error: RequestError, ctx: NextPageContext) => {
	const { req } = ctx ?? {};

	if (
		error.statusError === RequestStatusError.HttpUnauthorized ||
		error.statusError === RequestStatusError.RpcUnauthenticated
	) {
		const redirectUrl = req?.url || window.location.pathname;

		addLog(
			{
				message: 'Agent tried to enter the portal without login into',
				actionDispatched: `Redirected to: ${signInRoute}`,
				...error.logMetadata,
				pageRoute: getIsomorphicPathname(ctx),
				redirectionAfterLoginTo: redirectUrl
			},
			LogLevel.Info
		);

		setCookie(ctx, 'redirectUrl', redirectUrl, {
			path: '/',
			httpOnly: false
		});
		isomorphicRedirect(ctx, signInRoute);
	}
};
