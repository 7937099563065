import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import {
	ButtonLoading,
	FormError,
	FormItem,
	FormSection,
	Input,
	InputPassword,
	Label,
	PrimaryButton,
	Text,
	TextLink
} from '@agentero/components';
import { getInputStatusFromError } from '@agentero/utils';

import { AuthBox, AuthFormActions, AuthFormContainer } from 'packages/layout';
import { activateRoute, forgotPasswordRoute } from 'routes';

import { SignInStatusError } from './signInForm/SignInStatusError';
import { useSignInForm } from './signInForm/useSignInForm';

export const SignInForm = () => {
	const { t } = useTranslation('signIn');
	const signInForm = useSignInForm();

	const {
		onSubmit,
		handleSubmit,
		register,
		formState: { errors, isSubmitting }
	} = signInForm;
	const status = signInForm.getValues('status');

	return (
		<AuthBox data-cy="signIn-box">
			<SignInStatusError status={status} />

			<Text size={{ xs: 'L', md: 'XL' }} mb={32} as="h1">
				<b>{t('title')}</b>
			</Text>

			<AuthFormContainer>
				<form onSubmit={handleSubmit(onSubmit)} noValidate>
					<FormSection>
						<FormItem>
							<Label data-cy="signIn-email" htmlFor="email">
								{t('form.labels.email')}
							</Label>
							<Input
								id={'email'}
								type="email"
								autoComplete="off"
								{...register('email')}
								status={getInputStatusFromError(errors.email?.message)}
								aria-invalid={!!errors.email?.message}
								aria-errormessage="emailErr"
								autoFocus
							/>
							<FormError id="emailErr" error={errors.email?.message} />
						</FormItem>

						<FormItem>
							<Label data-cy="signIn-password" htmlFor="password">
								{t('form.labels.password')}
							</Label>
							<InputPassword
								id="password"
								autoComplete="off"
								{...register('password')}
								status={getInputStatusFromError(errors.password?.message)}
								aria-invalid={!!errors.password?.message}
								aria-errormessage="passwordErr"
							/>
							<FormError id="passwordErr" error={errors.password?.message as string} />
						</FormItem>
					</FormSection>

					<AuthFormActions>
						<PrimaryButton
							data-cy="signIn-submit"
							type="submit"
							isBig
							css={{ minWidth: 136 }}
							disabled={isSubmitting}>
							<ButtonLoading isLoading={isSubmitting}>{t('form.submit')}</ButtonLoading>
						</PrimaryButton>
					</AuthFormActions>
				</form>
			</AuthFormContainer>

			<Text mt={16} size="S">
				<TextLink href={forgotPasswordRoute}>{t('form.forgotPassword.text')}</TextLink>
			</Text>

			<Text
				data-cy="signIn-activateText"
				mt={32}
				size="S"
				colorize={colors => colors.gray.lighten40}>
				{t('form.footer.text')}

				<Link href={activateRoute} passHref legacyBehavior>
					<TextLink>{t('form.footer.action')}</TextLink>
				</Link>
			</Text>
		</AuthBox>
	);
};
